<template>
    <div :key="page_key">
        <LiefengModal title="标签管理" :value="display" :fullscreen="false" :width="600" :height="600" @input="onChangeModal(false)">
            <template v-slot:contentarea>
                <div class="container">
                    <div class="content">
                        <Form :model="formData" :label-colon="true" :label-width="100" :rules="formDataValidate" ref="form">
                            <Transfer
                                v-if="labelList"
                                class="label-community-transfer-box"
                                :data="labelList"
                                :target-keys="labelTargetKeys"
                                :list-style="listStyle"
                                :render-format="transferRender"
                                :titles="['源标签', '目标标签']"
                                filterable
                                @on-change="onChangeLabel"
                            ></Transfer>
                        </Form>
                    </div>
                </div>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" style="margin-right:10px" @click="onChangeModal(false)">取消</Button>
                <Button type="primary" @click="submit">确定</Button>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import RequestPage from "../../utils/page"
import Utils from "../../utils/utils"

export default {
    components: { LiefengModal },

    data() {
        return {
            display: false,
            page_key: this.$core.randomString(),

            labelList: null,
            labelTargetKeys: [],

            listStyle: {
                width: "250px",
                height: "500px",
            },
        }
    },

    async created() {},

    methods: {
        /**
         * 获取所有标签
         */
        getAllLabel() {
            return new Promise(resolve => {
              const page = new RequestPage(`/gateway/api/sycompany/pc/label/getLabelByPage?oemCode=${this.$core.getUserInfo().oemCode}&labelType=2`, {
                    onChange: res => {
                        if (page.isLoaded()) {
                            for (let i = 0; i < res.length; i++) {
                                const e = res[i]
                                e.key = e.labelCode
                            }

                            resolve(res)
                        } else {
                            page.load()
                        }
                    },
                })
            })
        },

        getAllTargerLabel() {
            return new Promise(resolve => {
                const page = new RequestPage(`/gateway/api/sycompany/pc/label/getOrgLabelByPage?orgCode=${this.orgCode}&orgType=${this.orgType}`, {
                    onChange: res => {
                        if (page.isLoaded()) {
                            const keys = []

                            for (let i = 0; i < res.length; i++) {
                                keys.push(res[i].labelCode)
                            }

                            resolve(keys)
                        } else {
                            page.load()
                        }
                    },
                })
            })
        },

        transferRender(item) {
            return item.labelCode + " - " + item.labelName
        },

        onChangeLabel(e) {
            this.labelTargetKeys = e
        },

        open(code, type) {
            this.orgCode = code
            this.orgType = type

            Promise.all([this.getAllLabel(), this.getAllTargerLabel()]).then(res => {
                this.labelList = res[0]
                this.labelTargetKeys = res[1]

                this.onChangeModal(true)
            })
        },

        /**
         * 提交事件
         */
        submit() {
            if (this.labelTargetKeys.length <= 0) {
                return this.$Message.error({
                    content: "请将标签添加至右侧目标框中",
                    background: true,
                })
            }

            const ids = []
            const lts = this.labelTargetKeys

            Utils.each(this.labelList, v => {
                // 根据标签 Code 获取标签 ID
                if (~lts.indexOf(v.labelCode)) {
                    ids.push(v.id)
                }
            })

            this.$post(
                `/gateway/api/sycompany/pc/label/assignOrgLabel`,
                {
                    labelIdList: ids,
                    orgCode: this.orgCode,
                    orgType: this.orgType,
                },
                { "Context-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success({
                        content: "提交成功",
                        background: true,
                    })
                    // 关闭表单窗口
                    this.onChangeModal(false)
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                }
            })
        },

        onChangeModal(flag) {
            // 改变弹窗显示状态
            this.display = flag
            // 如果为显示模式，刷新 Key
            if (flag) {
                this.page_key = this.$core.randomString()
            } else {
                // 父页面刷新列表
                this.$emit("resetBtn")
            }
        },

        getDetail(id) {
            this.$get("/info/api/pc/information/v2/queryById", {
                infoId: id,
                businessType: 2,
                functionType: 18,
                oemCode: "zjsm",
                operatorCustGlobalId: "C201026160002ff808181403869",
                terminal: 2,
            }).then(res => {
                if (res.code == 200) {
                    this.formData = res.data
                } else {
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                    return
                }
            })
        },

        /**
         * 监听图片上传成功事件
         */
        onSuccessUpload(e, item, key) {
            item[key] = e[1].url
        },
    },
}
</script>

<style lang="less" scoped>
.label-community-transfer-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 123px);
    /deep/.menu {
        height: 100%;
        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        padding-top: 20px;
        flex: 1;
        height: 100%;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}
</style>
